import React from 'react';
import { string } from 'prop-types';
import { navigate } from 'gatsby';
import loadable from '@loadable/component';
import { LOYALTY_STAMP_ROUTE } from 'constants/navigation';
import Skeleton from 'components/cards/skeleton';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStamp } from '@fortawesome/pro-regular-svg-icons';
import { faStamp as solid } from '@fortawesome/pro-solid-svg-icons';

import useStyles from './styles';

const BaseCard = loadable(() => import('components/cards/base-card'));

const StampLoyaltyCard = ({ passId }) => {
  const styles = useStyles();

  return <BaseCard
          classes={styles}
          onCardClick={() => navigate(`${LOYALTY_STAMP_ROUTE}?passId=${passId}`)}
          title="Add Loylaty Stamps"
          description="Add Loyalty Stamps to this Loyalty Card"
          buttonMessage="Add Loylaty"
          imageChild={
            <div className={styles.iconContainer}>
              <FontAwesomeIcon icon={faStamp} size="8x" className={styles.largeIcon}/>
            </div>
            }
          iconChild={
            <span className={styles.icon}>
              <FontAwesomeIcon icon={solid} />
            </span>
          }
          fallback={<Skeleton/>}
          />;
};

StampLoyaltyCard.propTypes = {
  passId: string.isRequired,
};

export default StampLoyaltyCard;
