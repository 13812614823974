import React from 'react';
import { string } from 'prop-types';
import StampLoyaltyCard from 'components/cards/stamp-loyalty';
import RedeemLoyaltyCard from 'components/cards/redeem-loyalty';
import useStyles from 'apputil/view-styles';

import { Grid } from '@material-ui/core';

const UpdateLoyaltyCardView = ({ passId }) => {
  const styles = useStyles();

  return (
    <div className={styles.menucontent}>
      <Grid spacing={2}
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="stretch"
        >
        <Grid item md={4} sm={6} xs={12}>
          <StampLoyaltyCard passId={passId}/>
        </Grid>

        <Grid item md={4} sm={6} xs={12}>
          <RedeemLoyaltyCard passId={passId}/>
        </Grid>
    </Grid>
  </div>
  );
};

UpdateLoyaltyCardView.propTypes = {
  passId: string.isRequired,
};

export default UpdateLoyaltyCardView;
